import React, { useState } from "react";
import Button from "../Components/Button";
import { flex, links } from "../constants";
import { HashLink } from "react-router-hash-link";
import Logo from "../assets/alana-match-logo.svg";
import { IoIosMenu, IoIosClose } from "react-icons/io";

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => setIsMenuOpen(false);

  const hover = "hover:text-opacity-80";

  return (
    <div className="bg-white relative z-20 lg:z-0 mx-auto px-5 lg:px-10 border shadow">
      <div id={links.navigation} className={"container" + flex}>
        <HashLink to="/" className="relative z-10">
          <img src={Logo} alt="Alana" className="w-28" />
        </HashLink>
        <ul
          className={
            flex + "gap-20 hidden lg:flex font-medium text-sm relative z-10"
          }
        >
          <li>
            <HashLink to={`#${links.hero}`} className={hover + "!text-black"}>
              Home
            </HashLink>
          </li>
          <li>
            <HashLink to={`#${links.about}`} className={hover + "!text-black"}>
              About
            </HashLink>
          </li>
          <li>
            <HashLink
              to={`#${links.contacts}`}
              className={hover + "!text-black"}
            >
              Reach out
            </HashLink>
          </li>
          <li>
            <HashLink to={`#${links.footer}`} className={hover + "!text-black"}>
              Contact Us
            </HashLink>
          </li>
        </ul>
        <Button className="lg:hidden rounded-xl" onClick={toggleMenu}>
          {isMenuOpen ? (
            <IoIosClose className="text-lg" />
          ) : (
            <IoIosMenu className="text-lg" />
          )}
          {isMenuOpen && (
            <ul className="gap-5 w-full !text-black grid  h-[90vh] left-0 top-0 font-light text-lg mt-[13vh] pt-[5vh] fixed z-20 bg-white">
              <li>
                <HashLink
                  to={`#${links.hero}`}
                  className={hover + "!text-white"}
                  onClick={closeMenu}
                >
                  Home
                </HashLink>
              </li>
              <li>
                <HashLink
                  to={`#${links.about}`}
                  className={hover + "!text-white font-medium"}
                  onClick={closeMenu}
                >
                  About
                </HashLink>
              </li>
              <li>
                <HashLink
                  to={`#${links.contacts}`}
                  className={hover + "!text-white font-medium"}
                  onClick={closeMenu}
                >
                  Reach out
                </HashLink>
              </li>
              <li>
                <HashLink
                  to={`#${links.footer}`}
                  className={hover + "!text-white font-medium"}
                  onClick={closeMenu}
                >
                  Contact Us
                </HashLink>
              </li>
              <li>
                <Button
                  label="Get Started"
                  onClick={() => {
                    window.location.assign("#contacts");
                    closeMenu();
                  }}
                  className="!bg-complementary text-white"
                />
              </li>
            </ul>
          )}
        </Button>
        <Button
          label="Get Started"
          onClick={() => window.location.assign("#contacts")}
          className="hidden lg:block !bg-complementary text-white"
        />
      </div>
    </div>
  );
};

export default Navigation;
