import woman from "../assets/woman.jpg";
import { GradientText } from "../Components";
import { links } from "../constants";

const About = () => {
  return (
    <div
      id={links.about}
      className={
        "container flex flex-col lg:flex-row items-center justify-center  gap-20 lg:gap-32  p-10 lg:p-20 mx-auto"
      }
    >
      <div className="relative">
        <img
          alt="woman"
          src={woman}
          className="absolute z-10 transform scale-125 w-56 blur-md opacity-20"
        />
        <img alt="woman" src={woman} className="relative z-10 w-56" />
      </div>
      <div className="lg:max-w-[40%] text-center lg:text-start ">
        <strong className="text-gray-500 text-sm">[ WHO I AM ]</strong>
        <GradientText className="text-3xl lg:text-5xl !from-[#000] !to-complementary my-5">
          A HUMAN FIRST, <br /> LIFE COACH{" "}
        </GradientText>
        <p className="text-sm lg:text-lg text-gray-600 mt-10">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec
          purus ut libero ultricies tincidunt. Sed nec eros in nunc ultricies
          lacinia. Donec eget erat nec nunc consequat auctor. Nam nec arcu sit
          amet ex aliquam tincidunt. Sed nec eros in nunc ultricies lacinia.
          Donec eget erat nec nunc consequat auctor. Nam nec arcu sit amet ex
          aliquam tincidunt.
        </p>
      </div>
    </div>
  );
};

export default About;
