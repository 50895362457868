import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ReachOut, Footer, Hero, About } from "./views";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          Component={() => (
            <div className="App bg-dark-purple min-h-screen">
              <Hero />
              <About />
              <ReachOut />
              <Footer />
            </div>
          )}
        />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
