import React, { ReactNode } from "react";

interface GradientTextProps {
  className?: string;
  children: ReactNode;
}

const GradientText = ({ className, children }: GradientTextProps) => {
  return (
    <div
      className={
        "text-6xl uppercase font-bold mb-2  bg-gradient-to-tr from-white to-neutral-light bg-clip-text text-transparent " +
        className
      }
    >
      {children}
    </div>
  );
};

export default GradientText;
