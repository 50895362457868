import React from "react";
import { center, links } from "../constants";
import { GradientText } from "../Components";
import heroImage from "../assets/hero.jpg";
import Button from "../Components/Button";
import Navigation from "./Navigation";

const Hero = () => {
  return (
    <div className="relative h-screen w-screen">
      <div className="absolute top-0 left-0 z-0 w-full h-full">
        <img
          src={heroImage}
          alt="heroImage"
          className="absolute top-0 left-0 w-full h-full object-cover lg:object-left-top z-0"
        />
        <div className="bg-black opacity-50 absolute w-full h-full" />
      </div>
      <Navigation />
      <div
        id={links.hero}
        className={
          center +
          "min-h-[600px] lg:min-h-[600px] absolute container mx-auto z-10 drop-shadow-lg transform top-[60%] left-[50%] -translate-x-[50%] -translate-y-[50%] "
        }
      >
        <GradientText className="!text-3xl lg:text-6xl text-center">
          Find your perfect match
        </GradientText>
        <p className="text-center text-sm lg:text-lg text-gray-100 w-[300px] lg:w-[500px] mx-auto">
          Labore esse deserunt nulla magna id nostrud dolor qui dolor laborum.
          Reprehenderit excepteur irure excepteur qui nisi non minim esse ad
          adipisicing sunt proident deserunt sit.
        </p>
        <Button
          label="Get started"
          onClick={() => window.location.assign("#contacts")}
          className="my-5 !bg-complementary text-white"
        />
      </div>
    </div>
  );
};

export default Hero;
