import React, { useRef } from "react";
import { CiCalendar, CiPhone, CiMail, CiUser, CiFlag1 } from "react-icons/ci";
import { Button, GradientText, Input, Select } from "../Components";
import { FaMountainCity, FaTreeCity } from "react-icons/fa6";
import BarLoader from "react-spinners/BarLoader";
import { IoSchoolOutline } from "react-icons/io5";
import { PiBriefcaseThin } from "react-icons/pi";
import { GiMoneyStack } from "react-icons/gi";
import { COLOR_VALUES } from "../enums";
import emailjs from "@emailjs/browser";
import { links } from "../constants";
import { useFormik } from "formik";

import { EducationLevelOptions, MaritalStatusOptions } from "../helper";
import { toast } from "react-toastify";

const today = new Date().toISOString().split("T")[0];

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  country: "",
  state: "",
  city: "",
  occupation: "",
  birthday: today,
  annualIncome: "",
  maritalStatus: "",
  educationLevel: "",
  file: null,
};

const ReachOut = () => {
  const form = useRef<HTMLFormElement>(null);

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      console.log("got here now");
      //0. validate birthday over 18
      const birthday = new Date(values.birthday);
      const now = new Date();
      const age = now.getFullYear() - birthday.getFullYear();
      if (age < 18) {
        toast("Minors are not allowed to use this service", {
          position: "top-right",
          progress: undefined,
          pauseOnHover: false,
          closeOnClick: true,
          autoClose: 3000,
          draggable: true,
          theme: "light",
          type: "error",
        });
        return setSubmitting(false);
      }

      if (form.current) {
        emailjs
          .send(
            process.env.REACT_APP_ServiceID as string,
            process.env.REACT_APP_TemplateID as string,
            values,
            {
              publicKey: process.env.REACT_APP_TemplateParams as string,
            }
          )
          .then(
            (res) => {
              toast(res.text, {
                position: "top-right",
                progress: undefined,
                pauseOnHover: false,
                closeOnClick: true,
                autoClose: 3000,
                draggable: true,
                theme: "light",
                type: "error",
              });
              formik.resetForm({ values: initialValues });
            },
            (error) => {
              toast(error, {
                position: "top-right",
                progress: undefined,
                pauseOnHover: false,
                closeOnClick: true,
                autoClose: 3000,
                draggable: true,
                theme: "light",
                type: "error",
              });
            }
          )
          .finally(() => setSubmitting(false));
      }
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const file = event.currentTarget.files[0];
      formik.setFieldValue("file", file);
    }
  };

  return (
    <div
      id={links.contacts}
      className="relative flex items-center flex-col z-10 p-10 lg:p-32 pb-5 bg-gray-100"
    >
      <GradientText className="!text-center lg:text-left text-[12px] lg:!text-sm !from-complementary-light !to-[#555]">
        the only impossible journey is the one you never begin
      </GradientText>
      <GradientText className="!text-center lg:text-left my-5 !text-2xl lg:!text-4xl !from-[#555] !to-[#000]">
        The love of your life is waiting
      </GradientText>
      <p className="text-gray-700 text-center my-5 mx-auto max-w-2xl text-sm lg:text-base">
        Selective Search works with individuals who value their time,
        reputation, personal privacy and finances. We understand and respect the
        role a matchmaking <br /> firm should play — behind the scenes. <br />
        <br />
      </p>
      <form
        ref={form}
        className="w-11/12 mx-auto"
        onSubmit={formik.handleSubmit}
      >
        <div className="grid gap-5">
          <h3 className="text-black font-bold mx-5 mt-10">
            Contact Information
          </h3>
          <div className="w-full grid md:grid-cols-2 lg:grid-cols-3 gap-5">
            <Input
              name="email"
              placeholder="Email"
              type="email"
              required
              RightIcon={<CiMail color={COLOR_VALUES.black} />}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <Input
              name="phone"
              placeholder="Phone"
              type="tel"
              required
              RightIcon={<CiPhone color={COLOR_VALUES.black} />}
              onChange={formik.handleChange}
              value={formik.values.phone}
            />
            <Input
              name="country"
              placeholder="Country"
              RightIcon={<CiFlag1 color={COLOR_VALUES.black} />}
              onChange={formik.handleChange}
              value={formik.values.country}
              required
            />
            <Input
              name="state"
              placeholder="State"
              required
              RightIcon={<FaMountainCity color={COLOR_VALUES["light-gray"]} />}
              onChange={formik.handleChange}
              value={formik.values.state}
            />
            <Input
              name="city"
              placeholder="City"
              RightIcon={<FaTreeCity color={COLOR_VALUES["light-gray"]} />}
              required
              onChange={formik.handleChange}
              value={formik.values.city}
            />
          </div>

          <h3 className="text-black font-bold mx-5 mt-10">About You</h3>
          <div className="w-full grid md:grid-cols-2 lg:grid-cols-3 gap-5">
            <Input
              name="firstName"
              placeholder="First Name"
              required
              RightIcon={<CiUser color={COLOR_VALUES.black} />}
              onChange={formik.handleChange}
              value={formik.values.firstName}
            />
            <Input
              name="lastName"
              placeholder="Last Name"
              required
              RightIcon={<CiUser color={COLOR_VALUES.black} />}
              onChange={formik.handleChange}
              value={formik.values.lastName}
            />
            <Input
              required
              type="date"
              name="birthday"
              placeholder="Birthday"
              onChange={formik.handleChange}
              value={formik.values.birthday}
              max={new Date().toISOString().split("T")[0]}
              RightIcon={<CiCalendar color={COLOR_VALUES.black} />}
            />
            <Input
              required
              name="occupation"
              placeholder="Occupation"
              RightIcon={<PiBriefcaseThin color={COLOR_VALUES.black} />}
              onChange={formik.handleChange}
              value={formik.values.occupation}
            />
            <Input
              required
              name="annualIncome"
              placeholder="Annual Income"
              RightIcon={<GiMoneyStack color={COLOR_VALUES["light-gray"]} />}
              onChange={formik.handleChange}
              value={formik.values.annualIncome}
            />
            <Select
              required
              name="educationLevel"
              placeholder="Education Level"
              onChange={formik.handleChange}
              options={EducationLevelOptions}
              value={formik.values.educationLevel}
              RightIcon={<IoSchoolOutline color={COLOR_VALUES["light-gray"]} />}
            />
            <Select
              required
              name="maritalStatus"
              placeholder="Marital Status"
              options={MaritalStatusOptions}
              onChange={formik.handleChange}
              value={formik.values.maritalStatus}
              RightIcon={<IoSchoolOutline color={COLOR_VALUES["light-gray"]} />}
            />
          </div>

          <h3 className="text-black font-bold mx-5 mt-10">Profile Photos</h3>
          <div className="w-full grid md:grid-cols-2 lg:grid-cols-3 gap-5">
            <Input
              required
              type="file"
              name="file"
              accept="image/*"
              placeholder="Profile Photo"
              onChange={handleFileChange}
            />
          </div>

          <Button className="!bg-complementary w-10/12 lg:w-1/4 mx-auto my-10 !uppercase">
            {formik.isSubmitting ? (
              <BarLoader className="mx-auto my-2" />
            ) : (
              "Send"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ReachOut;
