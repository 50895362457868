export const hover = " hover:opacity-80 cursor-pointer ";
export const flex = " flex items-center justify-between ";
export const center = " flex items-center justify-center flex-col ";

export const links = {
  navigation: "navigation",
  contacts: "contacts",
  footer: "footer",
  about: "about",
  hero: "hero",
};
