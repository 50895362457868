import React, { SelectHTMLAttributes } from "react";

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  placeholder: string;
  options: { label: string; value: string }[];
  RightIcon?: JSX.Element;
};

const Select = ({ placeholder, options, RightIcon, ...rest }: SelectProps) => (
  <div className="p-3 rounded-full border bg-white w-full flex">
    {RightIcon}
    <select
      className="flex-1 px-3 capitalize outline-none border-none text-black font-normal text-sm bg-transparent"
      {...rest}
    >
      <option value="" disabled selected>
        {placeholder}
      </option>
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

export default Select;
