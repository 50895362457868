import { ReactNode } from "react";
import { hover } from "../constants";

interface ButtonProps {
  label?: string;
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
  type?: "submit" | "reset" | "button" | undefined;
}

const Button = ({ label, children, className, onClick, type }: ButtonProps) => {
  return (
    <button
      type={type}
      className={
        "text-xs border font-semibold bg-white text-black relative z-10 border-light-gray rounded-full px-5 py-3 capitalize" +
        hover +
        className
      }
      onClick={onClick}
    >
      {children || label}
    </button>
  );
};

export default Button;
