export const handleEmail = () => {
  const tag = document.createElement("a");
  tag.setAttribute("href", "mailto:Birorifr@gmail.com");
  tag.click();
};

export const MonthOptions = (() => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months.map((month, index) => ({
    label: month,
    value: (index + 1).toString().padStart(2, "0"),
  }));
})();

export const DayOptions = (() => {
  return Array.from({ length: 31 }, (_, i) => ({
    label: (i + 1).toString(),
    value: (i + 1).toString().padStart(2, "0"),
  }));
})();

export const YearOptions = (() => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: currentYear - 1970 + 1 }, (_, i) => {
    const year = 1970 + i;
    return {
      label: year.toString(),
      value: year.toString(),
    };
  });
})();

export const EducationLevelOptions = (() => {
  const educationLevels = [
    "High School",
    "Associate Degree",
    "Bachelor's Degree",
    "Master's Degree",
    "Doctorate",
    "Other",
  ];
  return educationLevels.map((level) => ({
    label: level,
    value: level,
  }));
})();

export const MaritalStatusOptions = (() => {
  const maritalStatus = [
    "Single",
    "Married",
    "Divorces",
    "Widowed",
    "Separated",
    "In a relationship",
    "Other",
  ];
  return maritalStatus.map((level) => ({
    label: level,
    value: level,
  }));
})();

export const HowDidYouHearAbtUsOptions = (() => {
  const howDidYouHearAbtUs = [
    "Internet Search",
    "Social Media",
    "Friend/Family",
    "Advertisement",
    "Event",
    "Other",
  ];
  return howDidYouHearAbtUs.map((level) => ({
    label: level,
    value: level,
  }));
})();

export const stateOptions = [
  { label: "Alabama", value: "Alabama" },
  { label: "Alaska", value: "Alaska" },
  { label: "Arizona", value: "Arizona" },
  { label: "Arkansas", value: "Arkansas" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Connecticut", value: "Connecticut" },
  { label: "Delaware", value: "Delaware" },
  { label: "Florida", value: "Florida" },
  { label: "Georgia", value: "Georgia" },
  { label: "Hawaii", value: "Hawaii" },
  { label: "Idaho", value: "Idaho" },
  { label: "Illinois", value: "Illinois" },
  { label: "Indiana", value: "Indiana" },
  { label: "Iowa", value: "Iowa" },
  { label: "Kansas", value: "Kansas" },
  { label: "Kentucky", value: "Kentucky" },
  { label: "Louisiana", value: "Louisiana" },
  { label: "Maine", value: "Maine" },
  { label: "Maryland", value: "Maryland" },
  { label: "Massachusetts", value: "Massachusetts" },
  { label: "Michigan", value: "Michigan" },
  { label: "Minnesota", value: "Minnesota" },
  { label: "Mississippi", value: "Mississippi" },
  { label: "Missouri", value: "Missouri" },
  { label: "Montana", value: "Montana" },
  { label: "Nebraska", value: "Nebraska" },
  { label: "Nevada", value: "Nevada" },
  { label: "New Hampshire", value: "New Hampshire" },
  { label: "New Jersey", value: "New Jersey" },
  { label: "New Mexico", value: "New Mexico" },
  { label: "New York", value: "New York" },
  { label: "North Carolina", value: "North Carolina" },
  { label: "North Dakota", value: "North Dakota" },
  { label: "Ohio", value: "Ohio" },
  { label: "Oklahoma", value: "Oklahoma" },
  { label: "Oregon", value: "Oregon" },
  { label: "Pennsylvania", value: "Pennsylvania" },
  { label: "Rhode Island", value: "Rhode Island" },
  { label: "South Carolina", value: "South Carolina" },
  { label: "South Dakota", value: "South Dakota" },
  { label: "Tennessee", value: "Tennessee" },
  { label: "Texas", value: "Texas" },
  { label: "Utah", value: "Utah" },
  { label: "Vermont", value: "Vermont" },
  { label: "Virginia", value: "Virginia" },
  { label: "Washington", value: "Washington" },
  { label: "West Virginia", value: "West Virginia" },
  { label: "Wisconsin", value: "Wisconsin" },
  { label: "Wyoming", value: "Wyoming" },
];
