import React from "react";
import { hover, links } from "../constants";
import WhatsappIcon from "../assets/whatsapp.svg";
import LinkedInIcon from "../assets/linkedin.svg";
import { HashLink } from "react-router-hash-link";
import InstagramIcon from "../assets/instagram.svg";
import { GradientText, Button } from "../Components";

const Footer = () => {
  return (
    <div className="w-full border-t border-border py-10 bg-[whitesmoke]">
      <div id={links.footer} className="px-10 container mx-auto ">
        <div className="container mx-auto grid lg:flex justify-between border-b border-border pb-10 mb-10">
          <div className="flex-[.5]">
            <GradientText className="!text-2xl mb-3 !from-complementary-dark">
              Alana Match Maker
            </GradientText>
            <p className="text-sm text-gray-600">
              Occaecat tempor sunt elit exercitation id ipsum duis anim sit amet
              id eiusmod ex. Labore Lorem Lorem magna officia occaecat aliqua
              laborum aute. Reprehenderit est culpa nulla velit pariatur enim
              consectetur ullamco elit ullamco. Pariatur laborum excepteur anim
              adipisicing.
            </p>
            <div className={"flex mt-5 gap-3"}>
              <Button className="rounded-xl !px-2 !py-1">
                <img src={WhatsappIcon} alt="whatsapp" />
              </Button>
              <Button className="rounded-xl !p-1">
                <img src={LinkedInIcon} alt="linkedIn" />
              </Button>
              <Button className="rounded-xl !p-1">
                <a href="https://www.instagram.com/alanamatch"><img src={InstagramIcon} alt="instagram" /></a>
              </Button>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-10 mt-10 lg:mt-0">
            <ul className="flex flex-col gap-5">
              <strong className="font-semibold text-base text-complementary-dark">
                Links
              </strong>
              <li className={"text-sm text-gray-700 " + hover}>
                <HashLink to={`#${links.navigation}`}>Home</HashLink>
              </li>
              <li className={"text-sm text-gray-700 " + hover}>
                <HashLink to={`#${links.contacts}`}>Contact us</HashLink>
              </li>
              <li className={"text-sm text-gray-700 " + hover}>
                <HashLink to={`#${links.about}`}>About us</HashLink>
              </li>
            </ul>
            <ul className="flex flex-col gap-5">
              <strong className="font-semibold text-base text-complementary-dark">
                Contacts
              </strong>
              <li className={"text-sm text-gray-700 " + hover}>
                <HashLink to="#">OpenLoveInc</HashLink>
              </li>
              <li className={"text-sm text-gray-700 " + hover}>
                <HashLink to="#">+1 (405) 408-4735</HashLink>
              </li>
              <li className={"text-sm text-gray-700 " + hover}>
                <HashLink to="mailto:Birorifr@gmail.com?subject=Reaching%20Out&body=I%20wanted%20to%20reach%20out%20to%20you%20because...">
                  Birorifr@gmail.com
                </HashLink>
              </li>
              <li className={"text-sm text-gray-700 " + hover}>
                <HashLink to="#">kk 989 st, California</HashLink>
              </li>
            </ul>
          </div>
        </div>
        <p className="text-center text-light text-sm text-gray-700">
          Copyright &copy; {new Date().getFullYear()} Alana - All right reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
