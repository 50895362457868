import React, { InputHTMLAttributes } from "react";
import { flex } from "../constants";

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  placeholder: string;
  RightIcon?: JSX.Element;
};

const Input = ({ placeholder, RightIcon, ...rest }: InputProps) => (
  // input component
  <div className={"p-3 rounded-full border bg-white w-full" + flex}>
    {RightIcon}
    <input
      type="text"
      placeholder={placeholder}
      className="flex-1 w-full px-3 capitalize outline-none border-none text-black font-normal text-sm"
      {...rest}
    />
  </div>
);

export default Input;
